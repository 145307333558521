import {
	DELETE_CLIENTS_ERROR,
	DELETE_CLIENTS_REQUEST,
	DELETE_CLIENTS_SUCCESS,
	DELET_BUNDLE_ADMIN_ERROR,
	DELET_BUNDLE_ADMIN_REQUEST,
	DELET_BUNDLE_ADMIN_SUCCESS,
	GET_ASSOCIATED_ERROR,
	GET_ASSOCIATED_REQUEST,
	GET_ASSOCIATED_SUCCESS,
	GET_BUNDLE_ADMIN_ERROR,
	GET_BUNDLE_ADMIN_FREE_ERROR,
	GET_BUNDLE_ADMIN_FREE_REQUEST,
	GET_BUNDLE_ADMIN_FREE_SUCCESS,
	GET_BUNDLE_ADMIN_REQUEST,
	GET_BUNDLE_ADMIN_SUCCESS,
	GET_CLIENTSWITHASSOCIATED_ERROR,
	GET_CLIENTSWITHASSOCIATED_REQUEST,
	GET_CLIENTSWITHASSOCIATED_SUCCESS,
	GET_CLIENTSWITHOUTASSOCIATED_ERROR,
	GET_CLIENTSWITHOUTASSOCIATED_REQUEST,
	GET_CLIENTSWITHOUTASSOCIATED_SUCCESS,
	GET_PARAMS_ERROR,
	GET_PARAMS_REQUEST,
	GET_PARAMS_SUCCESS,
	GET_USERS_ERROR,
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	POST_BUNDLE_ADMIN_ERROR,
	POST_BUNDLE_ADMIN_REQUEST,
	POST_BUNDLE_ADMIN_SUCCESS,
	POST_CLIENTS_ERROR,
	POST_CLIENTS_REQUEST,
	POST_CLIENTS_SUCCESS,
	POST_PARAM_ERROR,
	POST_PARAM_REQUEST,
	POST_PARAM_SUCCESS,
	PUT_USERS_ERROR,
	PUT_USERS_REQUEST,
	PUT_USERS_SUCCESS,
	RESET_ADMIN,
	PUT_CONFIRM_WAREHOUSE_ERROR,
	PUT_CONFIRM_WAREHOUSE_REQUEST,
	PUT_CONFIRM_WAREHOUSE_SUCCESS,
	GET_ORDERS_REVIWED_ERROR,
	GET_ORDERS_REVIWED_REQUEST,
	GET_ORDERS_REVIWED_SUCCESS,
	PUT_PARAMS_REQUEST,
	PUT_PARAMS_ERROR,
	PUT_PARAMS_SUCCESS,
	GET_BUNDLE_EXCEL_REQUEST,
	GET_BUNDLE_EXCEL_ERROR,
	GET_BUNDLE_EXCEL_SUCCESS,
	GET_BUNDLE_INVOICE_REQUEST,
	GET_BUNDLE_INVOICE_ERROR,
	GET_BUNDLE_INVOICE_SUCCESS,
	GET_BUNDLE_SENDED_REQUEST,
	GET_BUNDLE_SENDED_ERROR,
	GET_BUNDLE_SENDED_SUCCESS,
	PUT_BUNDLE_CONFIRM_ARRIVAL_REQUEST,
	PUT_BUNDLE_CONFIRM_ARRIVAL_ERROR,
	PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS,
	GET_CLIENTS_REQUEST,
	GET_CLIENTS_ERROR,
	GET_CLIENTS_SUCCESS,
	PUT_BUNDLE_CONFIRM_WEIGHTS_ERROR,
	PUT_BUNDLE_CONFIRM_WEIGHTS_REQUEST,
	PUT_BUNDLE_CONFIRM_WEIGHTS_SUCCESS,
	GET_CLIENTS_ORDERS_REQUEST,
	GET_CLIENTS_ORDERS_ERROR,
	GET_CLIENTS_ORDERS_SUCCESS,
	PUT_CLIENT_PAYMENT_REQUEST,
	PUT_CLIENT_PAYMENT_ERROR,
	PUT_CLIENT_PAYMENT_SUCCESS,
	PUT_ASSOCIATE_PAYMENT_REQUEST,
	PUT_ASSOCIATE_PAYMENT_ERROR,
	PUT_ASSOCIATE_PAYMENT_SUCCESS,
	GET_GENERATED_BUNDLES_REQUEST,
	GET_GENERATED_BUNDLES_ERROR,
	GET_GENERATED_BUNDLES_SUCCESS,
	POST_ORDER_DONT_MATCH_REQUEST,
	POST_ORDER_DONT_MATCH_ERROR,
	POST_ORDER_DONT_MATCH_SUCCESS,
	DELET_ORDER_ADMIN_SUCCESS,
	DELET_ORDER_ADMIN_ERROR,
	DELET_ORDER_ADMIN_REQUEST,
	PUT_ORDER_DELIVERY_ADMIN_REQUEST,
	PUT_ORDER_DELIVERY_ADMIN_ERROR,
	PUT_ORDER_DELIVERY_ADMIN_SUCCESS,
	PUT_ORDER_CLIENT_REQUEST,
	PUT_ORDER_CLIENT_ERROR,
	PUT_ORDER_CLIENT_SUCCESS,
	GET_DONTMACTH_REQUEST,
	GET_DONTMACTH_ERROR,
	GET_DONTMACTH_SUCCESS,
	PUT_DROPSHIPPING_REQUEST,
	PUT_DROPSHIPPING_ERROR,
	PUT_DROPSHIPPING_SUCCESS,
	PUT_DROPSHIPPING_UPDATE_REQUEST,
	PUT_DROPSHIPPING_UPDATE_ERROR,
	PUT_DROPSHIPPING_UPDATE_SUCCESS,
} from '../../consts/actionTypes';
import { 
	GET_DROPSHIPPING_ERROR, 
	GET_DROPSHIPPING_REQUEST, 
	GET_DROPSHIPPING_SUCCESS, 
	POST_UPLOAD_BUNDLE_INVOICE_REQUEST,
	POST_UPLOAD_BUNDLE_INVOICE_ERROR,
	POST_UPLOAD_BUNDLE_INVOICE_SUCCESS, 
} from '../../consts/adminTypes';

import * as adminTypes from '../../consts/adminTypes';

const initiaState = {
	generatedBundles: { items: [] },
	dontmatch: null
};

function adminReducer(state = initiaState, action) {
	switch (action.type) {
		case RESET_ADMIN:
			return {};

		case GET_USERS_REQUEST:
			return { ...state, isUsersLoading: true, users: null, error: null, errorUser: null };
		case GET_USERS_ERROR:
			return { ...state, isUsersLoading: false, users: null, error: action.error.response.data.message };
		case GET_USERS_SUCCESS:
			if (action.users) {
				return { ...state, isUsersLoading: false, users: action.users, user: null, error: null };
			}
			return { ...state, isUsersLoading: false, users: null, error: null };

		case PUT_USERS_REQUEST:
			return { ...state, isUserLoading: true, user: null, errorUser: null };
		case PUT_USERS_ERROR:
			return { ...state, isUserLoading: false, user: null, errorUser: action.error.response.data.message };
		case PUT_USERS_SUCCESS:
			if (action.user) {
				return { ...state, isUserLoading: false, user: action.user, errorUser: null, clients: null };
			}
			return { ...state, isUserLoading: false, user: null, errorUser: null };

		case GET_CLIENTS_REQUEST:
			return { ...state, isClientsLoading: true, clients: null, errorClients: null };
		case GET_CLIENTS_ERROR:
			return { ...state, isClientsLoading: false, clients: null, errorClients: action.error.response.data.message };
		case GET_CLIENTS_SUCCESS:
			if (action.clients) {
				return { ...state, isClientsLoading: false, clients: action.clients, errorClients: null };
			}
			return { ...state, isClientsLoading: false, clients: null, errorClients: null };

		case PUT_CLIENT_PAYMENT_REQUEST:
			return { ...state, isClientsPaymentLoading: true, clientsPayment: null, errorClientsPayment: null };
		case PUT_CLIENT_PAYMENT_ERROR:
			return {
				...state,
				isClientsPaymentLoading: false,
				clientsPayment: null,
				errorClientsPayment: action.error.response.data.message,
			};
		case PUT_CLIENT_PAYMENT_SUCCESS:
			if (action.putOrder) {
				return {
					...state,
					isClientsPaymentLoading: false,
					clientsPayment: action.putOrder,
					errorClientsPayment: null,
					bundles: null,
					bundlesFree: null,
					bundlesSended: null,
				};
			}
			return { ...state, isClientsPaymentLoading: false, clientsPayment: null, errorClientsPayment: null };

		case PUT_ASSOCIATE_PAYMENT_REQUEST:
			return { ...state, isAssociatePaymentLoading: true, associatePayment: null, errorAssociatePayment: null };
		case PUT_ASSOCIATE_PAYMENT_ERROR:
			return {
				...state,
				isAssociatePaymentLoading: false,
				associatePayment: null,
				errorAssociatePayment: action.error.response.data.message,
			};
		case PUT_ASSOCIATE_PAYMENT_SUCCESS:
			if (action.putOrder) {
				return {
					...state,
					isAssociatePaymentLoading: false,
					associatePayment: action.putOrder,
					errorAssociatePayment: null,
				};
			}
			return { ...state, isAssociatePaymentLoading: false, associatePayment: null, errorAssociatePayment: null };

		case GET_ASSOCIATED_REQUEST:
			return { ...state, isAssociatedLoading: true, associated: null, errorAssociated: null };
		case GET_ASSOCIATED_ERROR:
			return {
				...state,
				isAssociatedLoading: false,
				associated: null,
				errorAssociated: action.error.response.data.message,
			};
		case GET_ASSOCIATED_SUCCESS:
			if (action.associated) {
				return { ...state, isAssociatedLoading: false, associated: action.associated, errorAssociated: null };
			}
			return { ...state, isAssociatedLoading: false, associated: null, errorAssociated: null };
		case GET_CLIENTSWITHOUTASSOCIATED_REQUEST:
			return {
				...state,
				isclientsWithOutAssociatedLoading: true,
				clientsWithOutAssociated: null,
				errorclientsWithOutAssociated: null,
			};
		case GET_CLIENTSWITHOUTASSOCIATED_ERROR:
			return {
				...state,
				isclientsWithOutAssociatedLoading: false,
				clientsWithOutAssociated: null,
				errorclientsWithOutAssociated: action.error.response.data.message,
			};
		case GET_CLIENTSWITHOUTASSOCIATED_SUCCESS:
			if (action.clients) {
				return {
					...state,
					isclientsWithOutAssociatedLoading: false,
					clientsWithOutAssociated: action.clients,
					errorclientsWithOutAssociated: null,
				};
			}
			return {
				...state,
				isclientsWithOutAssociatedLoading: false,
				clientsWithOutAssociated: null,
				errorclientsWithOutAssociated: null,
			};
		case GET_CLIENTSWITHASSOCIATED_REQUEST:
			return {
				...state,
				isclientsWithAssociatedLoading: true,
				clientsWithAssociated: null,
				errorclientsWithAssociated: null,
			};
		case GET_CLIENTSWITHASSOCIATED_ERROR:
			return {
				...state,
				isclientsWithAssociatedLoading: false,
				clientsWithAssociated: null,
				errorclientsWithAssociated: action.error.response.data.message,
			};
		case GET_CLIENTSWITHASSOCIATED_SUCCESS:
			if (action.clients) {
				return {
					...state,
					isclientsWithAssociatedLoading: false,
					clientsWithAssociated: action.clients,
					errorclientsWithAssociated: null,
				};
			}
			return {
				...state,
				isclientsWithAssociatedLoading: false,
				clientsWithAssociated: null,
				errorclientsWithAssociated: null,
			};

		case POST_CLIENTS_REQUEST:
			return { ...state, isPostClientsLoading: true, clientsPost: null, errorClientsPost: null };
		case POST_CLIENTS_ERROR:
			return {
				...state,
				isPostClientsLoading: false,
				clientsPost: null,
				errorClientsPost: action.error.response.data.message,
			};
		case POST_CLIENTS_SUCCESS:
			if (action.clients) {
				return { ...state, isPostClientsLoading: false, clientsPost: action.clients, errorClientsPost: null };
			}
			return { ...state, isPostClientsLoading: false, clientsPost: null, errorClientsPost: null };
		case DELETE_CLIENTS_REQUEST:
			return { ...state, isclientsDelete: true, clientsDelete: null, errorClientsDelete: null };
		case DELETE_CLIENTS_ERROR:
			return {
				...state,
				isclientsDelete: false,
				clientsDelete: null,
				errorClientsDelete: action.error.response.data.message,
			};
		case DELETE_CLIENTS_SUCCESS:
			if (action.clients) {
				return { ...state, isclientsDelete: false, clientsDelete: action.clients, errorClientsDelete: null };
			}
			return { ...state, isclientsDelete: false, clientsDelete: null, errorClientsDelete: null };

		// CRU Param
		case GET_PARAMS_REQUEST:
			return { ...state, isParamsLoading: true, params: null, errorParams: null };
		case GET_PARAMS_ERROR:
			return { ...state, isParamsLoading: false, params: null, errorParams: action.error.response.data.message };
		case GET_PARAMS_SUCCESS:
			if (action.params) {
				return { ...state, isParamsLoading: false, params: action.params, errorParams: null };
			}
			return { ...state, isParamsLoading: false, errorParams: null, paramPut: null, errorPutParam: null };

		case POST_PARAM_REQUEST:
			return { ...state, isParamPostLoading: true, paramPost: null, errorPostParam: null };
		case POST_PARAM_ERROR:
			return {
				...state,
				isParamPostLoading: false,
				paramPost: null,
				errorPostParam: action.error.response.data.message,
			};
		case POST_PARAM_SUCCESS:
			if (action.paramOk) {
				return { ...state, isParamPostLoading: false, paramPost: action.paramOk, errorPostParam: null };
			}
			return { ...state, isParamPostLoading: false, paramPost: null, errorPostParam: null };

		case PUT_PARAMS_REQUEST:
			return { ...state, isParamPutLoading: true, paramPut: null, errorPutParam: null };
		case PUT_PARAMS_ERROR:
			return { ...state, isParamPutLoading: false, paramPut: null, errorPutParam: action.error.response.data.message };
		case PUT_PARAMS_SUCCESS:
			if (action.paramOk) {
				return { ...state, isParamPutLoading: false, paramPut: action.paramOk, errorPutParam: null };
			}
			return { ...state, isParamPutLoading: false, paramPut: null, errorPutParam: null };

		// CRD Bultos
		case GET_BUNDLE_ADMIN_REQUEST:
			return {
				...state,
				isBundlesLoading: true,
				bundles: null,
				errorBundles: null,
				errorBundlesFree: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_ADMIN_ERROR:
			return {
				...state,
				isBundlesLoading: false,
				bundles: null,
				errorBundles: action.error.response?.data?.message,
				errorBundlesFree: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_ADMIN_SUCCESS:
			if (action.bundles) {
				return {
					...state,
					isBundlesLoading: false,
					bundles: action.bundles,
					errorBundles: null,
					errorPostBundles: null,
					errorDeletBundles: null,
					errorBundlesFree: null,
				};
			}
			return {
				...state,
				isBundlesLoading: false,
				bundles: null,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
				errorBundlesFree: null,
			};

		case GET_BUNDLE_ADMIN_FREE_REQUEST:
			return {
				...state,
				isBundlesFreeLoading: true,
				bundlesFree: null,
				errorBundlesFree: null,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_ADMIN_FREE_ERROR:
			return {
				...state,
				isBundlesFreeLoading: false,
				bundlesFree: null,
				errorBundlesFree: action.error.response?.data?.message,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_ADMIN_FREE_SUCCESS:
			if (action.bundlesFree) {
				return {
					...state,
					isBundlesFreeLoading: false,
					bundlesFree: action.bundlesFree,
					errorBundlesFree: null,
					errorBundles: null,
					errorPostBundles: null,
					errorDeletBundles: null,
				};
			}
			return {
				...state,
				isBundlesFreeLoading: false,
				bundlesFree: null,
				errorBundlesFree: null,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};

		case POST_BUNDLE_ADMIN_REQUEST:
			return {
				...state,
				isPostBundlesLoading: true,
				postBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case POST_BUNDLE_ADMIN_ERROR:
			return {
				...state,
				isPostBundlesLoading: false,
				postBundles: null,
				errorPostBundles: action.error.response?.data?.message,
				errorDeletBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case POST_BUNDLE_ADMIN_SUCCESS:
			if (action.bundleOk) {
				return {
					...state,
					isPostBundlesLoading: false,
					postBundles: action.bundleOk,
					errorPostBundles: null,
					bundles: null,
					bundlesFree: null,
					errorBundlesFree: null,
					errorBundles: null,
					errorDeletBundles: null,
				};
			}
			return {
				...state,
				isPostBundlesLoading: false,
				postBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};

		case DELET_BUNDLE_ADMIN_REQUEST:
			return {
				...state,
				isdeletBundlesLoading: true,
				isLoadingAdminGlobal: true,
				deletBundles: null,
				errorDeletBundles: null,
				errorPostBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case DELET_BUNDLE_ADMIN_ERROR:
			return {
				...state,
				isdeletBundlesLoading: false,
				isLoadingAdminGlobal: false,
				deletBundles: null,
				errorDeletBundles: action.error.response?.data?.message,
				errorPostBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case DELET_BUNDLE_ADMIN_SUCCESS:
			if (action.bundleDelete) {
				return {
					...state,
					isdeletBundlesLoading: false,
					isLoadingAdminGlobal: false,
					deletBundles: action.bundleDelete,
					errorDeletBundles: null,
					bundlesFree: null,
					bundles: null,
					errorBundlesFree: null,
					errorBundles: null,
					errorPostBundles: null,
				};
			}
			return {
				...state,
				isdeletBundlesLoading: false,
				isLoadingAdminGlobal: false,
				deletBundles: null,
				errorDeletBundles: null,
				errorPostBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
				postBundles: null,
				bundlesExcel: null,
				errorBundlesExcel: null,
			};

		case GET_BUNDLE_EXCEL_REQUEST:
			return { ...state, isBundlesExcelLoading: true, bundlesExcel: null, errorBundlesExcel: null };
		case GET_BUNDLE_EXCEL_ERROR:
			return {
				...state,
				isBundlesExcelLoading: false,
				bundlesExcel: null,
				errorBundlesExcel: action.error.response?.data?.message,
			};
		case GET_BUNDLE_EXCEL_SUCCESS:
			if (action.bundlesExcel) {
				// se resetean los otros valores
				return {
					...state,
					isBundlesExcelLoading: false,
					bundlesExcel: action.bundlesExcel,
					errorBundlesExcel: null,
					bundles: null,
					bundlesFree: null,
					bundlesSended: null,
				};
			}
			return { ...state, isBundlesExcelLoading: false, bundlesExcel: null, errorBundlesExcel: null };

		case GET_BUNDLE_INVOICE_REQUEST:
			return { ...state, isBundlesInvoiceLoading: true, bundlesInvoice: null, errorInvoicesExcel: null };
		case GET_BUNDLE_INVOICE_ERROR:
			return {
				...state,
				isBundlesInvoiceLoading: false,
				bundlesInvoice: null,
				errorInvoicesExcel: action.error.response?.data?.message,
			};
		case GET_BUNDLE_INVOICE_SUCCESS:
			if (action.bundlesInvoice) {
				return {
					...state,
					isBundlesInvoiceLoading: false,
					bundlesInvoice: action.bundlesInvoice,
					errorInvoicesExcel: null,
				};
			}
			return { ...state, isBundlesInvoiceLoading: false, bundlesInvoice: null, errorInvoicesExcel: null };
		
		case POST_UPLOAD_BUNDLE_INVOICE_REQUEST:
			return { ...state, isBundleInvoiceLoading: true, currentBundleLoading: action.payload.id, updatedBundle: null, errorUploadInvoice: null };
		case POST_UPLOAD_BUNDLE_INVOICE_ERROR:
			return {
				...state,
				isBundleInvoiceLoading: false,
				updatedBundle: null,
				currentBundleLoading: null,
				errorUploadInvoice: action.error.response?.data?.message,
			};
		case POST_UPLOAD_BUNDLE_INVOICE_SUCCESS:
			if (action.updatedBundle) {
				return {
					...state,
					isBundleInvoiceLoading: false,
					updatedBundle: action.updatedBundle,
					errorUploadInvoice: null,
					currentBundleLoading: null,
				};
			}

			return { ...state, isBundleInvoiceLoading: false, updatedBundle: null, errorUploadInvoice: null, currentBundleLoading: null };
	
			case adminTypes.PUT_BUNDLE_VALUE_REQUEST:
				return { ...state, isBundleValueLoading: true, currentBundleLoading: action.payload.id, updatedBundle: null, errorUpdatedValue: null };
			case adminTypes.PUT_BUNDLE_VALUE_ERROR:
				return {
					...state,
					isBundleValueLoading: false,
					updatedBundle: null,
					currentBundleLoading: null,
					errorUpdatedValue: action.error.response?.data?.message,
				};
			case adminTypes.PUT_BUNDLE_VALUE_SUCCESS:
				if (action.updatedBundle) {
					return {
						...state,
						isBundleValueLoading: false,
						updatedBundle: action.updatedBundle,
						errorUpdatedValue: null,
						currentBundleLoading: null,
					};
				}
	
				return { ...state, isBundleValueLoading: false, updatedBundle: null, errorUpdatedValue: null, currentBundleLoading: null };
		
		// Arrival
		case GET_BUNDLE_SENDED_REQUEST:
			return { ...state, isBundlesSendedLoading: true, bundlesSended: null, errorSendedExcel: null };
		case GET_BUNDLE_SENDED_ERROR:
			return {
				...state,
				isBundlesSendedLoading: false,
				bundlesSended: null,
				errorSendedExcel: action.error.response?.data?.message,
			};
		case GET_BUNDLE_SENDED_SUCCESS:
			if (action.bundles) {
				return { ...state, isBundlesSendedLoading: false, bundlesSended: action.bundles, errorSendedExcel: null };
			}
			return { ...state, isBundlesSendedLoading: false, bundlesSended: null, errorSendedExcel: null };

		case PUT_BUNDLE_CONFIRM_ARRIVAL_REQUEST:
			return { ...state, isBundlesArrivalLoading: true, bundlesArrival: null, errorBundleArrival: null };
		case PUT_BUNDLE_CONFIRM_ARRIVAL_ERROR:
			return {
				...state,
				isBundlesArrivalLoading: false,
				bundlesArrival: null,
				errorBundleArrival: action.error.response?.data?.message,
			};
		case PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS:
			if (action.bundlesArrival) {
				return {
					...state,
					isBundlesArrivalLoading: false,
					bundlesArrival: action.bundlesArrival,
					errorBundleArrival: null,
					bundlesSended: null,
					errorSendedExcel: null,
				};
			}
			return {
				...state,
				isBundlesArrivalLoading: false,
				bundlesArrival: null,
				errorBundleArrival: null,
				bundlesWeights: null,
				errorBundlesWeight: null,
			};

		case PUT_BUNDLE_CONFIRM_WEIGHTS_REQUEST:
			return { ...state, isBundlesWeightsLoading: true, bundlesWeights: null, errorBundlesWeight: null };
		case PUT_BUNDLE_CONFIRM_WEIGHTS_ERROR:
			return {
				...state,
				isBundlesWeightsLoading: false,
				bundlesWeights: null,
				errorBundlesWeight: action.error.response?.data?.message,
			};
		case PUT_BUNDLE_CONFIRM_WEIGHTS_SUCCESS:
			if (action.bundlesWeights) {
				return {
					...state,
					isBundlesWeightsLoading: false,
					bundlesWeights: action.bundlesWeights,
					errorBundlesWeight: null,
					bundlesSended: null,
					errorSendedExcel: null,
				};
			}
			return { ...state, isBundlesWeightsLoading: false, bundlesWeights: null, errorBundlesWeight: null };

		case PUT_CONFIRM_WAREHOUSE_REQUEST:
			return { ...state, isConfirmWorehouseLoading: true, confirmWorehouse: null, errorConfirmWorehouse: null };
		case PUT_CONFIRM_WAREHOUSE_ERROR:
			return {
				...state,
				isConfirmWorehouseLoading: false,
				confirmWorehouse: null,
				errorConfirmWorehouse: action.error.response.data.message,
			};
		case PUT_CONFIRM_WAREHOUSE_SUCCESS:
			if (action.confirmWorehouse) {
				return {
					...state,
					isConfirmWorehouseLoading: false,
					confirmWorehouse: action.confirmWorehouse,
					errorConfirmWorehouse: null,
					orders: null,
					errorOrders: null,
				};
			}
			return {
				...state,
				isConfirmWorehouseLoading: false,
				confirmWorehouse: null,
				errorConfirmWorehouse: null,
				confirmTableWorehouse: null,
				errorConfirmTableWorehouse: null,
			};

		case POST_ORDER_DONT_MATCH_REQUEST:
			return { ...state, isOrderDontMatchLoading: true, orderDontMatch: null, errorOrderDontMatch: null };
		case POST_ORDER_DONT_MATCH_ERROR:
			return {
				...state,
				isOrderDontMatchLoading: false,
				orderDontMatch: null,
				errorOrderDontMatch: action.error.response?.data?.message,
			};
		case POST_ORDER_DONT_MATCH_SUCCESS:
			if (action.order) {
				return { ...state, isOrderDontMatchLoading: false, orderDontMatch: action.order, errorOrderDontMatch: null };
			}
			return {
				...state,
				isOrderDontMatchLoading: false,
				orderDontMatch: null,
				errorOrderDontMatch: null,
				errorDontMatch: null,
				confirmWorehouse: null,
				errorConfirmWorehouse: null,
			};

		case GET_ORDERS_REVIWED_REQUEST:
			return { ...state, isOrdersLoading: true, orders: null, errorOrders: null };
		case GET_ORDERS_REVIWED_ERROR:
			return { ...state, isOrdersLoading: false, orders: null, errorOrders: action.error.response?.data?.message };
		case GET_ORDERS_REVIWED_SUCCESS:
			if (action.orders) {
				return { ...state, isOrdersLoading: false, orders: action.orders, errorOrders: null };
			}
			return { ...state, isOrdersLoading: false, orders: null, errorOrders: null };

		case GET_GENERATED_BUNDLES_REQUEST:
			return { ...state, isGeneratedBundleLoading: true, generatedBundles: { items: [] }, errorGeneratedBundle: null };
		case GET_GENERATED_BUNDLES_ERROR:
			return {
				...state,
				isGeneratedBundleLoading: false,
				generatedBundles: { items: [] },
				errorGeneratedBundle: action.error.response?.data?.message,
			};
		case GET_GENERATED_BUNDLES_SUCCESS:
			if (action.generatedBundles) {
				return {
					...state,
					isGeneratedBundleLoading: false,
					generatedBundles: action.generatedBundles,
					errorGeneratedBundle: null,
				};
			}
			return { ...state, isGeneratedBundleLoading: false, generatedBundles: { items: [] }, errorGeneratedBundle: null };

		// Order
		case GET_CLIENTS_ORDERS_REQUEST:
			return {
				...state,
				isClientsOrdersLoading: true,
				clientsOrders: null,
				errorClientsOrders: null,
				deleteOrder: null,
				errorDeleteOrder: null,
			};
		case GET_CLIENTS_ORDERS_ERROR:
			return {
				...state,
				isClientsOrdersLoading: false,
				clientsOrders: null,
				errorClientsOrders: action.error.response.data.message,
			};
		case GET_CLIENTS_ORDERS_SUCCESS:
			if (action.orders) {
				return {
					...state,
					isClientsOrdersLoading: false,
					clientsOrders: action.orders,
					errorClientsOrders: null,
					clientsPayment: null,
					associatePayment: null,
					putOrderDelivery: null,
					orderClient: null,
				};
			}
			return { ...state, isClientsOrdersLoading: false, clientsOrders: null, errorClientsOrders: null };

		case DELET_ORDER_ADMIN_REQUEST:
			return { ...state, isDeleteOrderLoading: true, deleteOrder: null, errorDeleteOrder: null };
		case DELET_ORDER_ADMIN_ERROR:
			return {
				...state,
				isDeleteOrderLoading: false,
				deleteOrder: null,
				errorDeleteOrder: action.error.response?.data?.message,
			};
		case DELET_ORDER_ADMIN_SUCCESS:
			if (action.deleteOrder) {
				return { ...state, isDeleteOrderLoading: false, deleteOrder: action.deleteOrder, errorDeleteOrder: null };
			}
			return {
				...state,
				isDeleteOrderLoading: false,
				deleteOrder: null,
				errorDeleteOrder: null,
				errorAssociatePayment: null,
				errorClientsPayment: null,
				errorPutOrderDelivery: null,
				errorOrderClient: null,
			};

		case PUT_ORDER_DELIVERY_ADMIN_REQUEST:
			return { ...state, isPutOrderDeliveryLoading: true, putOrderDelivery: null, errorPutOrderDelivery: null };
		case PUT_ORDER_DELIVERY_ADMIN_ERROR:
			return {
				...state,
				isPutOrderDeliveryLoading: false,
				putOrderDelivery: null,
				errorPutOrderDelivery: action.error.response?.data?.message,
			};
		case PUT_ORDER_DELIVERY_ADMIN_SUCCESS:
			if (action.putOrderDelivery) {
				return {
					...state,
					isPutOrderDeliveryLoading: false,
					putOrderDelivery: action.putOrderDelivery,
					errorPutOrderDelivery: null,
				};
			}
			return { ...state, isPutOrderDeliveryLoading: false, putOrderDelivery: null, errorPutOrderDelivery: null };

		case PUT_ORDER_CLIENT_REQUEST:
			return { ...state, isOrderClientLoading: true, orderClient: null, errorOrderClient: null };
		case PUT_ORDER_CLIENT_ERROR:
			return {
				...state,
				isOrderClientLoading: false,
				orderClient: null,
				errorOrderClient: action.error.response?.data?.message,
			};
		case PUT_ORDER_CLIENT_SUCCESS:
			if (action.putOrderClient) {
				return { ...state, isOrderClientLoading: false, orderClient: action.putOrderClient, errorOrderClient: null };
			}
			return { ...state, isOrderClientLoading: false, orderClient: null, errorOrderClient: null };

		// dont match
		case GET_DONTMACTH_REQUEST:
			return { ...state, isDontMatchLoading: true, dontmatch: state.dontmatch, errorDontMatch: null };
		case GET_DONTMACTH_ERROR:
			return {
				...state,
				isDontMatchLoading: false,
				dontmatch: null,
				errorDontMatch: action.error.response?.data?.message,
			};
		case GET_DONTMACTH_SUCCESS:
			if (action.dontmatch) {
				return { ...state, isDontMatchLoading: false, dontmatch: action.dontmatch, errorDontMatch: null };
			}
			return { ...state, isDontMatchLoading: false, dontmatch: null, errorDontMatch: null };

		// Dropshippin
		case GET_DROPSHIPPING_REQUEST:
			return { ...state, isDropshippingLoading: true, dropshipping: null, errorDropshipping: null };
		case GET_DROPSHIPPING_ERROR:
			return {
				...state,
				isDropshippingLoading: false,
				dropshipping: null,
				errorDropshipping: action.error.response.data.message,
			};
		case GET_DROPSHIPPING_SUCCESS:
			if (action.dropshipping) {
				return { ...state, isDropshippingLoading: false, dropshipping: action.dropshipping, errordropshipping: null };
			}
			return { ...state, isDropshippingLoading: false, dropshipping: null, errorDropshipping: null };

		case PUT_DROPSHIPPING_REQUEST:
			return { ...state, isPutDropshippingLoading: true, putDropshipping: null, errorPutDropshipping: null };
		case PUT_DROPSHIPPING_ERROR:
			return {
				...state,
				isPutDropshippingLoading: false,
				putDropshipping: null,
				errorPutDropshipping: action.error.response?.data?.message,
			};
		case PUT_DROPSHIPPING_SUCCESS:
			if (action.response) {
				return {
					...state,
					isPutDropshippingLoading: false,
					putDropshipping: action.response,
					errorputDropshipping: null,
				};
			}
			return { ...state, isPutDropshippingLoading: false, putDropshipping: null, errorputDropshipping: null };

		case PUT_DROPSHIPPING_UPDATE_REQUEST:
			return {
				...state,
				isPutDropshippingUpdateLoading: true,
				putDropshippingUpdate: null,
				errorPutDropshippingUpdate: null,
			};
		case PUT_DROPSHIPPING_UPDATE_ERROR:
			return {
				...state,
				isPutDropshippingUpdateLoading: false,
				putDropshippingUpdate: null,
				errorPutDropshippingUpdate: action.error.response?.data?.message,
			};
		case PUT_DROPSHIPPING_UPDATE_SUCCESS:
			if (action.response) {
				return {
					...state,
					isPutDropshippingUpdateLoading: false,
					putDropshippingUpdate: action.response,
					errorPutDropshippingUpdate: null,
				};
			}
			return {
				...state,
				isPutDropshippingUpdateLoading: false,
				putDropshippingUpdate: null,
				errorPutDropshippingUpdate: null,
			};

		default:
			return state;
	}
}

export default adminReducer;
