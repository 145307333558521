import { put, call } from 'redux-saga/effects';
import {
	GET_ORDERS_ASSOCIATED_ERROR,
	GET_ORDERS_ASSOCIATED_SUCCESS,
	GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_ERROR,
	GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_SUCCESS,
	POST_ORDER_UPDATE_TRACKING_ASSOCIATED_ERROR,
	POST_ORDER_UPDATE_TRACKING_ASSOCIATED_SUCCESS,
	POST_ORDER_UPDATE_VALUE_ASSOCIATED_ERROR,
	POST_ORDER_UPDATE_VALUE_ASSOCIATED_SUCCESS,
	GET_BUNDLE_SUCCESS,
	GET_BUNDLE_ERROR,
	POST_BUNDLE_SUCCESS,
	POST_BUNDLE_ERROR,
	GET_BUNDLE_FREE_SUCCESS,
	GET_BUNDLE_FREE_ERROR,
	DELET_BUNDLE_SUCCESS,
	GET_CLIENTS_OF_ASSOCIATED_SUCCESS,
	GET_CLIENTS_OF_ASSOCIATED_ERROR,
	GET_CLIENTS_ORDERS_OF_ASSOCIATED_SUCCESS,
	GET_CLIENTS_ORDERS_OF_ASSOCIATED_ERROR,
	GET_PROFIT_PAID_SUCCESS,
	GET_PROFIT_PAID_ERROR,
	GET_PROFIT_PENDINGOFPAY_SUCCESS,
	GET_PROFIT_PENDINGOFPAY_ERROR,
	DELET_ORDER_ASSOCIATED_SUCCESS,
	DELET_ORDER_ASSOCIATED_ERROR,
	GET_BUNDLE_PROFIT_SUCCESS,
	GET_BUNDLE_PROFIT_ERROR,
	GET_BUNDLE_ASSOCIATE_ERROR,
	GET_BUNDLE_ASSOCIATE_SUCCESS,
	POST_INCOMPLETE_ORDER_SUCCESS,
	POST_INCOMPLETE_ORDER_ERROR,
	GET_BUNDLE_INVOICE_SUCCESS,
	GET_BUNDLE_INVOICE_ERROR,
} from '../../consts/actionTypes';
import {
	deleteOfAssociated,
	getOfAssociated,
	patchOfAssociated,
	postOfAssociated,
	putOfAssociated,
} from '../api/associated';
import { ACTIVATE_ALERT } from '../../consts/utilsTypes';
import {
	DELETE_ITEM_PURCHASE_ASSOCIATED_ERROR,
	DELETE_ITEM_PURCHASE_ASSOCIATED_SUCCESS,
	GET_DROPSHIPPING_ASSOCIATED_ERROR,
	GET_DROPSHIPPING_ASSOCIATED_SUCCESS,
	GET_PURCHASE_ASSOCIATED_ERROR,
	GET_PURCHASE_ASSOCIATED_SUCCESS,
	GET_PURCHASE_ID_ASSOCIATED_ERROR,
	GET_PURCHASE_ID_ASSOCIATED_SUCCESS,
	PATCH_DROPSHIPPING_ASSOCIATED_ERROR,
	PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS,
	PATCH_PURCHASE_ASSOCIATED_ERROR,
	PATCH_PURCHASE_ASSOCIATED_SUCCESS,
	POST_APPROVE_PURCHASE_ASSOCIATED_ERROR,
	POST_APPROVE_PURCHASE_ASSOCIATED_SUCCESS,
	POST_REVIEW_PURCHASE_ASSOCIATED_ERROR,
	POST_REVIEW_PURCHASE_ASSOCIATED_SUCCESS,
	PUT_DROPSHIPPING_ASSOCIATED_ERROR,
	PUT_DROPSHIPPING_ASSOCIATED_SUCCESS,
	POST_UPLOAD_BUNDLE_INVOICE_SUCCESS,
	POST_UPLOAD_BUNDLE_INVOICE_ERROR,
	PUT_BUNDLE_VALUE_SUCCESS,
	PUT_BUNDLE_VALUE_ERROR,
} from '../../consts/associatedTypes';

const {
	VITE_APP_INREVIEW,
	VITE_APP_REVIEWED,
	VITE_APP_INWAREHOUSEMIA,
	VITE_APP_SENDED,
	VITE_APP_ARRIVED,
	VITE_APP_INWAREHOUSEUIO,
	VITE_APP_PENDINGOFPAY,
	VITE_APP_PAID,
	VITE_APP_RECEIVED_AND_DELIVERED,
} = import.meta.env;

export function* getPurchaseAssociatedRequestSaga({ payload }) {
	try {
		const resPurchases = yield call(
			getOfAssociated,
			'/purchase/associate?status=inReview&status=pendingChanges&status=accepted' + payload.url
		);
		yield put({ type: GET_PURCHASE_ASSOCIATED_SUCCESS, resPurchases });
	} catch (error) {
		yield put({ type: GET_PURCHASE_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* getPurchaseIdAssociatedRequestSaga({ payload }) {
	try {
		const resPurchase = yield call(getOfAssociated, '/purchase/' + payload.id);
		yield put({ type: GET_PURCHASE_ID_ASSOCIATED_SUCCESS, resPurchase });
	} catch (error) {
		yield put({ type: GET_PURCHASE_ID_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* patchPurchaseAssociatedRequestSaga({ payload }) {
	try {
		const purchase = yield call(patchOfAssociated, '/purchase/' + payload.id, payload.body);
		yield put({ type: PATCH_PURCHASE_ASSOCIATED_SUCCESS, purchase });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: 'Actualizado con éxito', type: 'success' } });
		// No se esta enviando el id a la pantalla por lo que no actualiza el que ya esta creado y tampoco lo eliminaria, ya que necesiat el id
	} catch (error) {
		yield put({ type: PATCH_PURCHASE_ASSOCIATED_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: error.response.data.message[0], type: 'error' },
		});
	}
}

export function* deleteItemPurchaseAssociatedRequestSaga({ payload }) {
	try {
		yield call(deleteOfAssociated, '/purchase/' + payload.id + '/invoice/detail/' + payload.itemId);
		yield put({ type: DELETE_ITEM_PURCHASE_ASSOCIATED_SUCCESS, id: payload.id, itemId: payload.itemId });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: 'Eliminado con éxito', type: 'success' } });
	} catch (error) {
		yield put({ type: DELETE_ITEM_PURCHASE_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* postApprovePurchaseAssociatedRequestSaga({ payload }) {
	try {
		// payload es el valor id ya que se envia solo el valor en el componente y no un objeto
		const orders = yield call(postOfAssociated, '/purchase/' + payload + '/approve');
		yield put({ type: POST_APPROVE_PURCHASE_ASSOCIATED_SUCCESS, orders });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Orden aprobada con éxito', type: 'success' },
		});
	} catch (error) {
		yield put({ type: POST_APPROVE_PURCHASE_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* postReviewPurchaseAssociatedRequestSaga({ payload }) {
	try {
		// payload es el valor id ya que se envia solo el valor en el componente y no un objeto
		const orders = yield call(postOfAssociated, '/purchase/' + payload.id + '/review', payload.body);
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Revisión enviada con éxito', type: 'success' },
		});
		yield put({ type: POST_REVIEW_PURCHASE_ASSOCIATED_SUCCESS, orders });
	} catch (error) {
		yield put({ type: POST_REVIEW_PURCHASE_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* getOrdersToTrackingRequestSaga({ payload }) {
	try {
		const orders = yield call(
			getOfAssociated,
			'/order/associate?status=' + VITE_APP_INREVIEW + '&status=' + VITE_APP_REVIEWED + '' + payload.url
		);
		yield put({ type: GET_ORDERS_ASSOCIATED_SUCCESS, orders });
	} catch (error) {
		yield put({ type: GET_ORDERS_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* putOrdersToUpdateTrackingRequestSaga({ payload }) {
	try {
		// eslint-disable-next-line no-unused-vars
		const order = yield call(putOfAssociated, '/order/associate/tracking/' + payload.id, payload.body);
		yield put({ type: POST_ORDER_UPDATE_TRACKING_ASSOCIATED_SUCCESS, order: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: payload.msgSuccess, type: 'success' } });
	} catch (error) {
		yield put({ type: POST_ORDER_UPDATE_TRACKING_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* getOrdersInWarehouseMiaRequestSaga({ payload }) {
	try {
		const ordersInWarehouseMia = yield call(
			getOfAssociated,
			'/order/associate?status=' + VITE_APP_INWAREHOUSEMIA + '' + payload.url
		);
		yield put({ type: GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_SUCCESS, ordersInWarehouseMia });
	} catch (error) {
		yield put({ type: GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_ERROR, error });
	}
}

export function* postOrdersToUpdateValueRequestSaga({ payload }) {
	try {
		// eslint-disable-next-line no-unused-vars
		const order = yield call(putOfAssociated, '/order/associate/value/' + payload.id, payload.body);
		yield put({ type: POST_ORDER_UPDATE_VALUE_ASSOCIATED_SUCCESS, value: 1 });
	} catch (error) {
		yield put({ type: POST_ORDER_UPDATE_VALUE_ASSOCIATED_ERROR, error });
	}
}

// Bultos
export function* getBundleRequestSaga() {
	try {
		const bundles = yield call(getOfAssociated, '/bundle?status=' + VITE_APP_INWAREHOUSEMIA);
		yield put({ type: GET_BUNDLE_SUCCESS, bundles });
	} catch (error) {
		yield put({ type: GET_BUNDLE_ERROR, error });
	}
}

export function* getBundleAssociateRequestSaga() {
	try {
		const bundles = yield call(getOfAssociated, '/bundle/associate?status=' + VITE_APP_INWAREHOUSEUIO);
		yield put({ type: GET_BUNDLE_ASSOCIATE_SUCCESS, bundles });
	} catch (error) {
		yield put({ type: GET_BUNDLE_ASSOCIATE_ERROR, error });
	}
}

export function* getBundleFreeRequestSaga({ payload }) {
	try {
		const { orderBy, mode } = payload.query;
		const bundlesFree = yield call(getOfAssociated, '/bundle/free?orderBy=' + orderBy + '&mode=' + mode);
		yield put({ type: GET_BUNDLE_FREE_SUCCESS, bundlesFree });
	} catch (error) {
		yield put({ type: GET_BUNDLE_FREE_ERROR, error });
	}
}

export function* getBundleProfitRequestSaga() {
	try {
		const bundlesProfit = yield call(
			getOfAssociated,
			'/bundle?status=' + VITE_APP_ARRIVED + '&status=' + VITE_APP_INWAREHOUSEUIO
		);
		yield put({ type: GET_BUNDLE_PROFIT_SUCCESS, bundlesProfit });
	} catch (error) {
		yield put({ type: GET_BUNDLE_PROFIT_ERROR, error });
	}
}

export function* postBundleRequestSaga({ payload }) {
	try {
		yield call(postOfAssociated, '/bundle', payload.body);
		yield put({ type: POST_BUNDLE_SUCCESS, bundleOk: 1 });
	} catch (error) {
		yield put({ type: POST_BUNDLE_ERROR, error });
	}
}

export function* deletBundleRequestSaga({ payload }) {
	try {
		yield call(deleteOfAssociated, '/bundle/' + payload.id);
		yield put({ type: DELET_BUNDLE_SUCCESS, bundleDelete: 1 });
	} catch (error) {
		yield put({ type: POST_BUNDLE_ERROR, error });
	}
}

export function* postUploadBundleInvoiceAssociateRequestSaga({ payload }) {
	try {
		const {id, body} = payload;
		const updatedBundle = yield call(postOfAssociated, '/bundle/' + id + '/invoice', body);
		yield put({ type: POST_UPLOAD_BUNDLE_INVOICE_SUCCESS, updatedBundle });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Factura cargada exitosamente.', type: 'success' },
		});

		yield call(getBundleRequestSaga);
	} catch (error) {
		yield put({ type: POST_UPLOAD_BUNDLE_INVOICE_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: error.response.data.message, type: 'error' },
		});
	}
}

export function* putBundleValueAssociateRequestSaga({ payload }) {
	try {
		const { id, body } = payload;
		const updatedBundle = yield call(putOfAssociated, '/bundle/' + id + '/value', body);
		yield put({ type: PUT_BUNDLE_VALUE_SUCCESS, updatedBundle });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Bulto actualizado exitosamente.', type: 'success' },
		});
		
		yield call(getBundleRequestSaga);
	} catch (error) {
		yield put({ type: PUT_BUNDLE_VALUE_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: error.response.data.message, type: 'error' },
		});
	}
}

export function* getBundleInvoiceAssociateRequestSaga({ payload }) {
	try {
		const bundlesInvoice = yield call(getOfAssociated, '/bundle/' + payload.id + '/invoice', { responseType: 'blob' });
		yield put({ type: GET_BUNDLE_INVOICE_SUCCESS, bundlesInvoice });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Facturas descargadas exitosamente.', type: 'success' },
		});
	} catch (error) {
		yield put({ type: GET_BUNDLE_INVOICE_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Se ha producido un error al descargar las facturas.', type: 'error' },
		});
	}
}



export function* getClientsOfAssociatedRequestSaga() {
	try {
		const clients = yield call(getOfAssociated, '/associate/clients');
		yield put({ type: GET_CLIENTS_OF_ASSOCIATED_SUCCESS, clients });
	} catch (error) {
		yield put({ type: GET_CLIENTS_OF_ASSOCIATED_ERROR, error });
	}
}

export function* getClientsOrdersOfAssociatedRequestSaga({ payload }) {
	try {
		const clients = yield call(
			getOfAssociated,
			'/order/client/' +
				payload +
				'?status=' +
				VITE_APP_INREVIEW +
				'&status=' +
				VITE_APP_REVIEWED +
				'&status=' +
				VITE_APP_INWAREHOUSEMIA +
				'&status=' +
				VITE_APP_SENDED +
				'&status=' +
				VITE_APP_ARRIVED +
				'&status=' +
				VITE_APP_INWAREHOUSEUIO +
				'&status=' +
				VITE_APP_PENDINGOFPAY +
				'&status=' +
				VITE_APP_PAID +
				'&status=' +
				VITE_APP_RECEIVED_AND_DELIVERED +
				'&page=1&limit=100'
		);
		yield put({ type: GET_CLIENTS_ORDERS_OF_ASSOCIATED_SUCCESS, clients });
	} catch (error) {
		yield put({ type: GET_CLIENTS_ORDERS_OF_ASSOCIATED_ERROR, error });
	}
}

export function* getProfitPaidRequestSaga() {
	try {
		const profit = yield call(getOfAssociated, '/associate/orders?page=1&limit=100&status=' + VITE_APP_PAID);
		yield put({ type: GET_PROFIT_PAID_SUCCESS, profit });
	} catch (error) {
		yield put({ type: GET_PROFIT_PAID_ERROR, error });
	}
}

export function* getProfitPendingOfPayRequestSaga() {
	try {
		const profit = yield call(
			getOfAssociated,
			'/associate/orders?page=1&limit=100&status=' + VITE_APP_PENDINGOFPAY + '&status=' + VITE_APP_INWAREHOUSEUIO
		);
		yield put({ type: GET_PROFIT_PENDINGOFPAY_SUCCESS, profit });
	} catch (error) {
		yield put({ type: GET_PROFIT_PENDINGOFPAY_ERROR, error });
	}
}

// order
export function* deleteOrderAssociatedRequestSaga({ payload }) {
	try {
		yield call(deleteOfAssociated, '/order/', payload);
		yield put({ type: DELET_ORDER_ASSOCIATED_SUCCESS, deleteOrder: 1 });
	} catch (error) {
		yield put({ type: DELET_ORDER_ASSOCIATED_ERROR, error });
	}
}

// Dropshipping

export function* getDropshippingAssociatedRequestSaga({ payload }) {
	try {
		const dropshipping = yield call(getOfAssociated, '/assisted-purchase?' + payload);
		yield put({ type: GET_DROPSHIPPING_ASSOCIATED_SUCCESS, dropshipping });
	} catch (error) {
		yield put({ type: GET_DROPSHIPPING_ASSOCIATED_ERROR, error });
	}
}

export function* patchDropshippingAssociatedRequestSaga({ payload }) {
	try {
		yield call(patchOfAssociated, '/assisted-purchase/' + payload.id, payload.body); // response obj = de y para el back
		yield put({ type: PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS, response: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: PATCH_DROPSHIPPING_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS }); // reset
	}
}

export function* putDropshippingAssociatedSaga({ payload }) {
	try {
		yield call(putOfAssociated, '/assisted-purchase/' + payload.id + '/invoice', payload.body, {
			'Content-Type': 'multipart/form-data',
		});
		yield put({ type: PUT_DROPSHIPPING_ASSOCIATED_SUCCESS, response: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: PUT_DROPSHIPPING_ASSOCIATED_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: PUT_DROPSHIPPING_ASSOCIATED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: PUT_DROPSHIPPING_ASSOCIATED_SUCCESS }); // reset
	}
}

export function* postIncompleteOrderRequestSaga({ payload }) {
	try {
		yield call(patchOfAssociated, '/order/update-incomplete', payload.body);
		yield put({ type: POST_INCOMPLETE_ORDER_SUCCESS, PostIncompleteOrder: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: POST_INCOMPLETE_ORDER_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: POST_INCOMPLETE_ORDER_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message[0], type: 'error' } });
		yield put({ type: POST_INCOMPLETE_ORDER_SUCCESS }); // reset
	}
}
