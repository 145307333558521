import { put, call } from 'redux-saga/effects';
import {
	DELETE_CLIENTS_ERROR,
	DELETE_CLIENTS_SUCCESS,
	DELET_BUNDLE_ADMIN_SUCCESS,
	DELET_BUNDLE_ADMIN_ERROR,
	GET_ASSOCIATED_ERROR,
	GET_ASSOCIATED_SUCCESS,
	GET_BUNDLE_ADMIN_ERROR,
	GET_BUNDLE_ADMIN_FREE_ERROR,
	GET_BUNDLE_ADMIN_FREE_SUCCESS,
	GET_BUNDLE_ADMIN_SUCCESS,
	GET_CLIENTSWITHASSOCIATED_ERROR,
	GET_CLIENTSWITHASSOCIATED_SUCCESS,
	GET_CLIENTSWITHOUTASSOCIATED_ERROR,
	GET_CLIENTSWITHOUTASSOCIATED_SUCCESS,
	GET_PARAMS_ERROR,
	GET_PARAMS_SUCCESS,
	GET_USERS_ERROR,
	GET_USERS_SUCCESS,
	POST_BUNDLE_ADMIN_ERROR,
	POST_BUNDLE_ADMIN_SUCCESS,
	POST_CLIENTS_ERROR,
	POST_CLIENTS_SUCCESS,
	POST_PARAM_ERROR,
	POST_PARAM_SUCCESS,
	PUT_PARAMS_ERROR,
	PUT_PARAMS_SUCCESS,
	PUT_USERS_ERROR,
	PUT_USERS_SUCCESS,
	PUT_CONFIRM_WAREHOUSE_SUCCESS,
	PUT_CONFIRM_WAREHOUSE_ERROR,
	GET_ORDERS_REVIWED_ERROR,
	GET_ORDERS_REVIWED_SUCCESS,
	GET_BUNDLE_SENDED_ERROR,
	GET_BUNDLE_EXCEL_SUCCESS,
	GET_BUNDLE_EXCEL_ERROR,
	GET_BUNDLE_INVOICE_SUCCESS,
	GET_BUNDLE_INVOICE_ERROR,
	GET_BUNDLE_SENDED_SUCCESS,
	PUT_BUNDLE_CONFIRM_ARRIVAL_ERROR,
	PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS,
	GET_CLIENTS_ERROR,
	GET_CLIENTS_SUCCESS,
	GET_CLIENTS_ORDERS_SUCCESS,
	GET_CLIENTS_ORDERS_ERROR,
	PUT_BUNDLE_CONFIRM_WEIGHTS_SUCCESS,
	PUT_BUNDLE_CONFIRM_WEIGHTS_ERROR,
	PUT_CLIENT_PAYMENT_SUCCESS,
	PUT_CLIENT_PAYMENT_ERROR,
	PUT_ASSOCIATE_PAYMENT_SUCCESS,
	PUT_ASSOCIATE_PAYMENT_ERROR,
	GET_GENERATED_BUNDLES_SUCCESS,
	GET_GENERATED_BUNDLES_ERROR,
	POST_ORDER_DONT_MATCH_SUCCESS,
	POST_ORDER_DONT_MATCH_ERROR,
	DELET_ORDER_ADMIN_ERROR,
	DELET_ORDER_ADMIN_SUCCESS,
	PUT_ORDER_DELIVERY_ADMIN_SUCCESS,
	PUT_ORDER_DELIVERY_ADMIN_ERROR,
	PUT_ORDER_CLIENT_SUCCESS,
	PUT_ORDER_CLIENT_ERROR,
	GET_DONTMACTH_SUCCESS,
	GET_DONTMACTH_ERROR,
	PUT_DROPSHIPPING_ERROR,
	PUT_DROPSHIPPING_SUCCESS,
	PUT_DROPSHIPPING_UPDATE_SUCCESS,
	PUT_DROPSHIPPING_UPDATE_ERROR,
} from '../../consts/actionTypes';
import { 
	GET_DROPSHIPPING_ERROR, 
	GET_DROPSHIPPING_SUCCESS, 
	POST_UPLOAD_BUNDLE_INVOICE_ERROR, 
	POST_UPLOAD_BUNDLE_INVOICE_SUCCESS, 
	PUT_BUNDLE_VALUE_ERROR, 
	PUT_BUNDLE_VALUE_SUCCESS
} from '../../consts/adminTypes';
import { ACTIVATE_ALERT } from '../../consts/utilsTypes';
import { getOfAdmin, putOfAdmin, postOfAdmin, deleteOfAdmin, patchOfAdmin } from '../api/admin';

const {
	VITE_APP_INREVIEW,
	VITE_APP_REVIEWED,
	VITE_APP_INWAREHOUSEMIA,
	VITE_APP_SENDED,
	VITE_APP_ARRIVED,
	VITE_APP_INWAREHOUSEUIO,
	VITE_APP_PENDINGOFPAY,
	VITE_APP_PAID,
	VITE_APP_RECEIVED_AND_DELIVERED,
} = import.meta.env;

export function* getUsersRequestSaga({ payload }) {
	try {
		const users = yield call(getOfAdmin, '/user?' + payload);
		yield put({ type: GET_USERS_SUCCESS, users });
	} catch (error) {
		yield put({ type: GET_USERS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* putUsersRequestSaga({ payload }) {
	try {
		const { id, data } = payload;
		const user = yield call(putOfAdmin, '/user/' + id, data);
		yield put({ type: PUT_USERS_SUCCESS, user });
		yield put({ type: ACTIVATE_ALERT, payload: { message: 'Usuario actualizado exitosamente.', type: 'success' } });
	} catch (error) {
		yield put({ type: PUT_USERS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* getClientsRequestSaga() {
	try {
		const clients = yield call(getOfAdmin, '/user?type=0&page=1&limit=100');
		yield put({ type: GET_CLIENTS_SUCCESS, clients });
	} catch (error) {
		yield put({ type: GET_CLIENTS_ERROR, error });
	}
}

export function* getClientsOrdersRequestSaga({ payload }) {
	try {
		const orders = yield call(
			getOfAdmin,
			'/order/client/' +
				payload +
				'?status=' +
				VITE_APP_INREVIEW +
				'&status=' +
				VITE_APP_REVIEWED +
				'&status=' +
				VITE_APP_INWAREHOUSEMIA +
				'&status=' +
				VITE_APP_SENDED +
				'&status=' +
				VITE_APP_ARRIVED +
				'&status=' +
				VITE_APP_INWAREHOUSEUIO +
				'&status=' +
				VITE_APP_PENDINGOFPAY +
				'&status=' +
				VITE_APP_PAID +
				'&status=' +
				VITE_APP_RECEIVED_AND_DELIVERED +
				'&page=1&limit=100'
		);
		yield put({ type: GET_CLIENTS_ORDERS_SUCCESS, orders });
	} catch (error) {
		yield put({ type: GET_CLIENTS_ORDERS_ERROR, error });
	}
}

export function* getAssociatedRequestSaga() {
	try {
		const associated = yield call(getOfAdmin, '/associate');
		yield put({ type: GET_ASSOCIATED_SUCCESS, associated });
	} catch (error) {
		yield put({ type: GET_ASSOCIATED_ERROR, error });
	}
}

export function* getClientsWithOutAssociatedRequestSaga() {
	try {
		const clients = yield call(getOfAdmin, '/client/without-associate');
		yield put({ type: GET_CLIENTSWITHOUTASSOCIATED_SUCCESS, clients });
	} catch (error) {
		yield put({ type: GET_CLIENTSWITHOUTASSOCIATED_ERROR, error });
	}
}

export function* getClientsWithAssociatedRequestSaga({ payload }) {
	try {
		const clients = yield call(getOfAdmin, '/associate/' + payload + '/client');
		yield put({ type: GET_CLIENTSWITHASSOCIATED_SUCCESS, clients });
	} catch (error) {
		yield put({ type: GET_CLIENTSWITHASSOCIATED_ERROR, error });
	}
}

export function* postClientsRequestSaga({ payload }) {
	try {
		const { id, data } = payload;
		const clients = yield call(postOfAdmin, '/associate/' + id + '/client', data);
		yield put({ type: POST_CLIENTS_SUCCESS, clients });
		yield put({ type: ACTIVATE_ALERT, payload: { message: 'Clientes agregados exitosamente.', type: 'success' } });
	} catch (error) {
		yield put({ type: POST_CLIENTS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* deleteClientsRequestSaga({ payload }) {
	try {
		const { id, data } = payload;
		yield call(deleteOfAdmin, '/associate/' + id + '/client', data);
		yield put({ type: DELETE_CLIENTS_SUCCESS, clients: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: 'Clientes eliminados exitosamente.', type: 'success' } });
	} catch (error) {
		yield put({ type: DELETE_CLIENTS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* confirmWarehouse({ payload }) {
	try {
		const confirmWorehouse = yield call(putOfAdmin, '/order/admin/confirm/warehouse', payload);
		yield put({ type: PUT_CONFIRM_WAREHOUSE_SUCCESS, confirmWorehouse });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Paquetes confirmados con éxito.', type: 'success' },
		});
		yield put({ type: POST_ORDER_DONT_MATCH_SUCCESS }); // resetOrderDontMatchRequest
	} catch (error) {
		yield put({ type: PUT_CONFIRM_WAREHOUSE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
		yield put({ type: POST_ORDER_DONT_MATCH_SUCCESS }); // resetOrderDontMatchRequest
	}
}

export function* postOrderDontMatchRequestSaga({ payload }) {
	try {
		yield call(postOfAdmin, '/order/admin/create/dont-match', payload);
		yield put({ type: POST_ORDER_DONT_MATCH_SUCCESS, order: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: 'Orden creada con éxito', type: 'success' } });
		yield put({ type: POST_ORDER_DONT_MATCH_SUCCESS }); // resetOrderDontMatchRequest
	} catch (error) {
		yield put({ type: POST_ORDER_DONT_MATCH_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
		yield put({ type: POST_ORDER_DONT_MATCH_SUCCESS }); // resetOrderDontMatchRequest
	}
}

export function* getOrdersAdminRequestSaga({ payload }) {
	try {
		const orders = yield call(getOfAdmin, '/order/admin?status=' + VITE_APP_REVIEWED + '' + payload.url);
		yield put({ type: GET_ORDERS_REVIWED_SUCCESS, orders });
	} catch (error) {
		yield put({ type: GET_ORDERS_REVIWED_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

// CRU Param
export function* getParamsRequestSaga() {
	try {
		const params = yield call(getOfAdmin, '/configuration-parameter');
		yield put({ type: GET_PARAMS_SUCCESS, params });
	} catch (error) {
		yield put({ type: GET_PARAMS_ERROR, error });
	}
}

export function* postParamRequestSaga({ payload }) {
	try {
		const param = yield call(postOfAdmin, '/configuration-parameter/create', payload.data);
		yield put({ type: POST_PARAM_SUCCESS, param });
	} catch (error) {
		yield put({ type: POST_PARAM_ERROR, error });
	}
}

export function* putParamRequestSaga({ payload }) {
	try {
		// eslint-disable-next-line no-unused-vars
		const paramOk = yield call(putOfAdmin, '/configuration-parameter/update-many', payload.data);
		yield put({ type: PUT_PARAMS_SUCCESS, paramOk: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: 'Parametros actualizado exitosamente.', type: 'success' } });
	} catch (error) {
		yield put({ type: PUT_PARAMS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

// CRD Bultos
export function* getBundleAdminRequestSaga() {
	try {
		const bundles = yield call(getOfAdmin, '/bundle?status=' + VITE_APP_INWAREHOUSEMIA);
		yield put({ type: GET_BUNDLE_ADMIN_SUCCESS, bundles });
	} catch (error) {
		yield put({ type: GET_BUNDLE_ADMIN_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* getBundleAdminFreeRequestSaga({ payload }) {
	try {
		const { orderBy, mode } = payload.query;
		const bundlesFree = yield call(getOfAdmin, '/bundle/free?orderBy=' + orderBy + '&mode=' + mode);
		yield put({ type: GET_BUNDLE_ADMIN_FREE_SUCCESS, bundlesFree });
	} catch (error) {
		yield put({ type: GET_BUNDLE_ADMIN_FREE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* postBundleAdminRequestSaga({ payload }) {
	try {
		yield call(postOfAdmin, '/bundle', payload.body);
		yield put({ type: POST_BUNDLE_ADMIN_SUCCESS, bundleOk: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: payload.msgSuccess, type: 'success' } });
	} catch (error) {
		console.log(error);
		yield put({ type: POST_BUNDLE_ADMIN_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* deletBundleAdminRequestSaga({ payload }) {
	try {
		yield call(deleteOfAdmin, '/bundle/' + payload.id);
		yield put({ type: DELET_BUNDLE_ADMIN_SUCCESS, bundleDelete: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: payload.msgSuccess, type: 'success' } });
	} catch (error) {
		yield put({ type: DELET_BUNDLE_ADMIN_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* getBundleExcelRequestSaga() {
	try {
		const bundlesExcel = yield call(getOfAdmin, '/bundle/excel', { responseType: 'blob' });
		yield put({ type: GET_BUNDLE_EXCEL_SUCCESS, bundlesExcel });
	} catch (error) {
		yield put({ type: GET_BUNDLE_EXCEL_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

// Bultos enviado y en bodega UIO
export function* getBundleInvoiceRequestSaga({ payload }) {
	try {
		const bundlesInvoice = yield call(getOfAdmin, '/bundle/' + payload.id + '/invoice', { responseType: 'blob' });
		yield put({ type: GET_BUNDLE_INVOICE_SUCCESS, bundlesInvoice });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Facturas descargadas exitosamente.', type: 'success' },
		});
	} catch (error) {
		yield put({ type: GET_BUNDLE_INVOICE_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Se ha producido un error al descargar las facturas.', type: 'error' },
		});
	}
}


export function* postUploadBundleInvoiceRequestSaga({ payload }) {
	try {
		const { id, body, isFromShipments = false } = payload;
		const updatedBundle = yield call(postOfAdmin, '/bundle/' + id + '/invoice', body);
		yield put({ type: POST_UPLOAD_BUNDLE_INVOICE_SUCCESS, updatedBundle });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Factura cargada exitosamente.', type: 'success' },
		});

		if (!isFromShipments) {
			yield call(getBundleAdminRequestSaga);
		} else {
			yield call(getBundleSendedRequestSaga)
		}
		
	} catch (error) {
		yield put({ type: POST_UPLOAD_BUNDLE_INVOICE_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: error.response.data.message, type: 'error' },
		});
	}
}

export function* putBundleValueRequestSaga({ payload }) {
	try {
		const { id, body } = payload;
		const updatedBundle = yield call(putOfAdmin, '/bundle/' + id + '/value', body);
		yield put({ type: PUT_BUNDLE_VALUE_SUCCESS, updatedBundle });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Bulto actualizado exitosamente.', type: 'success' },
		});
		
		yield call(getBundleAdminRequestSaga);
	} catch (error) {
		yield put({ type: PUT_BUNDLE_VALUE_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: error.response.data.message, type: 'error' },
		});
	}
}


export function* getBundleSendedRequestSaga() {
	try {
		const bundles = yield call(
			getOfAdmin,
			'/bundle?status=' + VITE_APP_SENDED + '&status=' + VITE_APP_ARRIVED + '&status=' + VITE_APP_INWAREHOUSEUIO
		);
		yield put({ type: GET_BUNDLE_SENDED_SUCCESS, bundles });
	} catch (error) {
		yield put({ type: GET_BUNDLE_SENDED_ERROR, error });
	}
}

export function* putBundleConfirmArrivalRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/bundle/confirm-arrival', payload.body);
		yield put({ type: PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS, bundlesArrival: 1 });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Bultos confirmados con exitos.', type: 'success' },
		});
	} catch (error) {
		yield put({ type: PUT_BUNDLE_CONFIRM_ARRIVAL_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* putBundleConfirmWeightslRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/bundle/confirm-weights', payload.body);
		yield put({ type: PUT_BUNDLE_CONFIRM_WEIGHTS_SUCCESS, bundlesWeights: 1 });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { open: true, message: 'Pesos actualizados con exitos.', type: 'success' },
		});
	} catch (error) {
		yield put({ type: PUT_BUNDLE_CONFIRM_WEIGHTS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* getGeneratedBundlesRequestSaga({ payload }) {
	try {
		const generatedBundles = yield call(getOfAdmin, '/bundle/generated-bundles-excel' + payload.query);
		yield put({ type: GET_GENERATED_BUNDLES_SUCCESS, generatedBundles });
	} catch (error) {
		yield put({ type: GET_GENERATED_BUNDLES_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

// order
export function* putClientPaymentRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/order/admin/paid/client/', payload);
		yield put({ type: PUT_CLIENT_PAYMENT_SUCCESS, putOrder: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: payload.msgSuccess, type: 'success' } });
	} catch (error) {
		yield put({ type: PUT_CLIENT_PAYMENT_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

export function* putAssociatePaymentRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/order/admin/paid/associate/', payload.body);
		yield put({ type: PUT_ASSOCIATE_PAYMENT_SUCCESS, putOrder: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: payload.msgSuccess, type: 'success' } });
	} catch (error) {
		yield put({ type: PUT_ASSOCIATE_PAYMENT_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

// Verificar el payload
export function* deleteOrderAdminRequestSaga({ payload }) {
	try {
		yield call(deleteOfAdmin, '/order/', payload);
		yield put({ type: DELET_ORDER_ADMIN_SUCCESS, deleteOrder: 1 });
	} catch (error) {
		yield put({ type: DELET_ORDER_ADMIN_ERROR, error });
	}
}

// Verificar el payload porque tiene un ternario con Order en el componente
export function* putOrderDeliveryAdminRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/order/delivery/', payload);
		yield put({ type: PUT_ORDER_DELIVERY_ADMIN_SUCCESS, putOrderDelivery: 1 });
	} catch (error) {
		yield put({ type: PUT_ORDER_DELIVERY_ADMIN_ERROR, error });
	}
}

export function* putOrderClientRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/order/update-client/' + payload.id, payload.body);
		yield put({ type: PUT_ORDER_CLIENT_SUCCESS, putOrderClient: 1 });
	} catch (error) {
		yield put({ type: PUT_ORDER_CLIENT_ERROR, error });
	}
}

export function* getDontMatchSaga() {
	try {
		const dontmatch = yield call(getOfAdmin, '/order/admin/dont-match');
		yield put({ type: GET_DONTMACTH_SUCCESS, dontmatch });
	} catch (error) {
		yield put({ type: GET_DONTMACTH_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { open: true, message: error.response.data.message, type: 'error' } });
	}
}

// Dropshipping

export function* getDropshippingRequestSaga({ payload }) {
	try {
		const dropshipping = yield call(getOfAdmin, '/assisted-purchase?' + payload);
		yield put({ type: GET_DROPSHIPPING_SUCCESS, dropshipping });
	} catch (error) {
		yield put({ type: GET_DROPSHIPPING_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* patchDropshippingRequestSaga({ payload }) {
	try {
		yield call(patchOfAdmin, '/assisted-purchase/' + payload.id, payload.body); // response obj = de y para el back
		yield put({ type: PUT_DROPSHIPPING_SUCCESS, response: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: PUT_DROPSHIPPING_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: PUT_DROPSHIPPING_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: PUT_DROPSHIPPING_SUCCESS }); // reset
	}
}

export function* putDropshippingUpdateRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, '/assisted-purchase/' + payload.id + '/invoice', payload.body, {
			'Content-Type': 'multipart/form-data',
		});
		yield put({ type: PUT_DROPSHIPPING_UPDATE_SUCCESS, response: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: PUT_DROPSHIPPING_UPDATE_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: PUT_DROPSHIPPING_UPDATE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: PUT_DROPSHIPPING_UPDATE_SUCCESS }); // reset
	}
}
