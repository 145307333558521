import * as types from '../../consts/actionTypes';
import * as typesAdmin from '../../consts/adminTypes';

export const getUsersRequest = payload => ({
	type: types.GET_USERS_REQUEST,
	payload,
});

export const putUsersRequest = payload => ({
	type: types.PUT_USERS_REQUEST,
	payload,
});

export const getClientsRequest = () => ({
	type: types.GET_CLIENTS_REQUEST,
});

export const getClientsOrdersRequest = payload => ({
	type: types.GET_CLIENTS_ORDERS_REQUEST,
	payload,
});

export const putClientPaymentRequest = payload => ({
	type: types.PUT_CLIENT_PAYMENT_REQUEST,
	payload,
});

export const putAssociatePaymentRequest = payload => ({
	type: types.PUT_ASSOCIATE_PAYMENT_REQUEST,
	payload,
});

export const getAssociatedRequest = () => ({
	type: types.GET_ASSOCIATED_REQUEST,
});

export const getClientsWithOutAssociatedRequest = () => ({
	type: types.GET_CLIENTSWITHOUTASSOCIATED_REQUEST,
});

export const getClientsWithAssociatedRequest = payload => ({
	type: types.GET_CLIENTSWITHASSOCIATED_REQUEST,
	payload,
});

export const postClientsRequest = payload => ({
	type: types.POST_CLIENTS_REQUEST,
	payload,
});

export const postClientsSuccess = () => ({
	type: types.POST_CLIENTS_SUCCESS,
});

export const deleteClientsRequest = payload => ({
	type: types.DELETE_CLIENTS_REQUEST,
	payload,
});

export const deleteClientsSuccess = () => ({
	type: types.DELETE_CLIENTS_SUCCESS,
});

export const clearAdmin = payload => ({
	type: types.RESET_ADMIN,
	payload,
});

// Confirmar en bodega MIA
export const confirmWarehouseRequest = payload => ({
	type: types.PUT_CONFIRM_WAREHOUSE_REQUEST,
	payload,
});

export const postOrderDontMatchRequest = payload => ({
	type: types.POST_ORDER_DONT_MATCH_REQUEST,
	payload,
});

export const resetOrderDontMatchRequest = () => ({
	type: types.POST_ORDER_DONT_MATCH_SUCCESS,
});

export const getOrdersAdminRequest = payload => ({
	type: types.GET_ORDERS_REVIWED_REQUEST,
	payload,
});

// Param
export const getParamsRequest = () => ({
	type: types.GET_PARAMS_REQUEST,
});

export const postParamRequest = payload => ({
	type: types.POST_PARAM_REQUEST,
	payload,
});

export const putParamRequest = payload => ({
	type: types.PUT_PARAMS_REQUEST,
	payload,
});

export const resetParamRequest = () => ({
	type: types.GET_PARAMS_SUCCESS,
});

// Proceso de asignacion o creación de bultos
export const getBundleAdminRequest = () => ({
	type: types.GET_BUNDLE_ADMIN_REQUEST,
});

export const getBundleAdminFreeRequest = payload => ({
	type: types.GET_BUNDLE_ADMIN_FREE_REQUEST,
	payload,
});

export const postBundleAdminRequest = payload => ({
	type: types.POST_BUNDLE_ADMIN_REQUEST,
	payload,
});

export const deletBundleAdminRequest = payload => ({
	type: types.DELET_BUNDLE_ADMIN_REQUEST,
	payload,
});

export const resetBundleAdminRequest = () => ({
	type: types.DELET_BUNDLE_ADMIN_SUCCESS,
});

export const getBundleExcelRequest = () => ({
	type: types.GET_BUNDLE_EXCEL_REQUEST,
});

export const getBundleInvoiceRequest = payload => ({
	type: types.GET_BUNDLE_INVOICE_REQUEST,
	payload,
});

// Bultos enviado y en bodega UIO
export const resetBundleInvoiceRequest = () => ({
	type: types.GET_BUNDLE_INVOICE_SUCCESS,
});

export const getBundleSendedRequest = () => ({
	type: types.GET_BUNDLE_SENDED_REQUEST,
});

export const putBundleConfirmArrivalRequest = payload => ({
	type: types.PUT_BUNDLE_CONFIRM_ARRIVAL_REQUEST,
	payload,
});

export const putBundleConfirmWeightslRequest = payload => ({
	type: types.PUT_BUNDLE_CONFIRM_WEIGHTS_REQUEST,
	payload,
});

export const resetBundleConfirmArrivalRequest = () => ({
	type: types.PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS,
});

export const getGeneratedBundleRequest = payload => ({
	type: types.GET_GENERATED_BUNDLES_REQUEST,
	payload,
});

// Order
export const deleteOrderAdminRequest = payload => ({
	type: types.DELET_ORDER_ADMIN_REQUEST,
	payload,
});

export const resetDeleteOrderAdminRequest = () => ({
	type: types.DELET_ORDER_ADMIN_SUCCESS,
});

export const putOrderDeliveryAdminRequest = payload => ({
	type: types.PUT_ORDER_DELIVERY_ADMIN_REQUEST,
	payload,
});

export const putOrderClientRequest = payload => ({
	type: types.PUT_ORDER_CLIENT_REQUEST,
	payload,
});

// dont match
export const getDontMatchRequest = () => ({
	type: types.GET_DONTMACTH_REQUEST,
});

// dropshipping
export const getDropshippingRequest = payload => ({
	type: typesAdmin.GET_DROPSHIPPING_REQUEST,
	payload,
});

export const patchDropshippingRequest = payload => ({
	type: types.PUT_DROPSHIPPING_REQUEST,
	payload,
});

export const putDropshippingUpdateRequest = payload => ({
	type: types.PUT_DROPSHIPPING_UPDATE_REQUEST,
	payload,
});

export const postUploadBundleInvoceRequest = payload => ({
	type: typesAdmin.POST_UPLOAD_BUNDLE_INVOICE_REQUEST,
	payload,
});

export const putBundleValueRequest = payload => ({
	type: typesAdmin.PUT_BUNDLE_VALUE_REQUEST,
	payload,
});